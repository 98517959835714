<template>
  <div>
    <BetaWarning />
    <SellOnlineDialog
      v-if="showSellOnlineDialog"
      :item="item"
      @closed="closeSellOnlineDialog"
    />
    <v-tabs-items v-model="selectedAppBarTab">
      <v-tab-item
        v-for="tab in tabs"
        :value="tab.key"
        :key="tab.key"
      >
        <v-container
          fluid
          style="background: #f0f0f0"
          v-if="tab.key === 'profile'"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
              order="3"
              order-md="1"
            >
              <ItemForm
                v-model="item"
                @updated="itemUpdated"
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
              order="1"
              order-md="2"
            >
              <v-row dense>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-btn
                    :disabled="processing"
                    :loading="disabling"
                    v-if="item.status"
                    dark
                    block
                    class="mb-5"
                    color="green"
                    @click="disable"
                  >
                    {{ $t('active') }}
                  </v-btn>
                  <v-btn
                    :disabled="processing"
                    :loading="activating"
                    v-if="!item.status"
                    dark
                    block
                    class="mb-5"
                    color="red"
                    @click="activate"
                  >
                    {{ $t('disabled') }}
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-card>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-avatar
                          color="primary"
                          size="48"
                        >
                          <span class="white--text title mr-1">{{ item.availability.current }}</span>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('currentAvailability') }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card>
                    <v-card-text>
                      <Images :current-images="item.images" />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="3"
              order="2"
              order-md="5"
            >
              <v-btn
                dark
                block
                class="mb-5 lighten-2"
                color="primary"
                @click="sellOnline"
              >
                {{ $t('sellOnline') }}
              </v-btn>
              <ItemBarcodeCard
                v-if="item.barcode !== null"
                :item="item"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

import ItemForm from '@/components/ItemForm'
import ItemBarcodeCard from '@/components/ItemBarcodeCard'
import BetaWarning from '@/components/common/BetaWarning'
import SellOnlineDialog from '@/components/SellOnlineDialog'
import Images from "@/components/item/Images"

export default {
	name: 'Items',
	components: {
		Images,
		BetaWarning,
		ItemForm,
		ItemBarcodeCard,
		SellOnlineDialog
	},
	data: () => ({
		item: null,
		processing: false,
		activating: false,
		disabling: false,
		showSellOnlineDialog: false
	}),
	computed: {
		selectedAppBarTab: function() {
			return this.$store.state.selectedAppBarTab
		},
		tabs() {

			const tabs = []

			tabs.push({
				title: this.$t('profile'),
				key: 'profile'
			})

			tabs.push({
				title: this.$t('variations'),
				key: 'variations'
			})

			tabs.push({
				title: this.$t('inventory'),
				key: 'inventory'
			})

			tabs.push({
				title: this.$t('sales'),
				key: 'sales'
			})

			tabs.push({
				title: this.$t('bookings'),
				key: 'bookings'
			})

			tabs.push({
				title: this.$t('subscriptions'),
				key: 'subscriptions'
			})

			return tabs
		}
	},
	methods: {
		itemUpdated(item) {
			console.log(item)
		},
		closeSellOnlineDialog() {
			this.showSellOnlineDialog = false
		},
		sellOnline() {
			this.showSellOnlineDialog = true
		},
		disable: function() {
			this.processing = true
			this.disabling = true
			this.$store.dispatch('updateItem', {
				uuid: this.item.uuid,
				status: false
			}).then().finally(() => {
				this.processing = false
				this.disabling = false
			})
		},
		activate: function() {
			this.processing = true
			this.activating = true
			this.$store.dispatch('updateItem', {
				uuid: this.item.uuid,
				status: true
			}).then().finally(() => {
				this.processing = true
				this.activating = true
			})
		}
	},
	beforeMount() {
		this.item = this.$store.state.items.find(item => item.uuid === this.$route.params.uuid)
	},
	mounted() {
		this.$store.commit('updateAppBarTabs', this.tabs)
		this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
		this.$store.commit('updateAppBarTitle', this.item.title)
	}
}
</script>
